<template>
  <v-card>
    <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
        Cadastrar uma cotação
        <v-spacer></v-spacer>
        <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>
    <v-container class="form">
      <v-form>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="pb-0 pt-0">
              <v-card-text
                class="pt-0 pl-0 pb-1 pr-0"
              >
                Máximo valor de prêmios
              </v-card-text>

              <v-row>
                <v-col cols="12" class="pb-0 pt-0" sm="6">
                  <!-- <v-text-field
                    single-line
                    outlined
                    dense
                    v-model="dataConfigLimites.maxPrizeValue"
                    :error-messages="palpiteError"
                    @input="$v.palpite.$touch()"
                    @blur="$v.palpite.$touch()"
                  /> -->

                  <v-currency-field
                    single-line
                    outlined
                    dense
                    v-model="dataConfigLimites.maxPrizeValue"
                    @input="$v.dataConfigLimites.maxPrizeValue.$touch()"
                    @blur="$v.dataConfigLimites.maxPrizeValue.$touch()"
                    prefix="R$"
                    :error-messages="valueErrors"/>
                </v-col>

                <v-col cols="12" class="pb-0 pt-0" sm="4">
                  <v-btn 
                    :color="variables.colorPrimary"
                    dark
                    @click="confirmarAlteracaoLimite"
                    :loading="loadingLimite"
                  >Alterar</v-btn>
                </v-col>
              </v-row>

            </v-col>
          </v-row>


          <v-data-table
            :headers="headers"
            :items="dataConfigLimites.modalities"
            dense
          >

            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="(item, index) in items"
                  :key="index"
                >
                  <td>{{ item.modalityId }}</td>
                  <td class="text-end">{{ item.value | currency }}</td>
                  <!-- <td>{{ item.maxHit }}</td> -->
                  <!-- <td>{{ sweepstakes.config.dtDraw }}</td> -->
                  <td class="text-end">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editItem(item, index)"
                    >
                      edit
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </template>

          </v-data-table>

        </v-card-text>
      </v-form>
    </v-container>


    <Modal 
      :activeModal="isActiveModal"
      :widthModal="450"
      @closeModal="closeModalAddCotacao"
    >
      <FormAddCotacao 
        @closeModal="closeModalAddCotacao"
        :activeModal="isActiveModal"
      />
    </Modal>

    <Modal
      :activeModal="isActiveModalConfirmacao"
      :widthModal="450"
      @closeModal="closeModalConfirmarAlteracao"
    >
      <ConfirmarAlteracao 
        @closeModal="closeModalConfirmarAlteracao"
        @confirmarAlteracao="confirmarAlteracao"
      />
    </Modal>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
// import axios from 'axios'
// import { required, email, requiredIf } from 'vuelidate/lib/validators'
import { required, minValue } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'

// import config from '@/core/config'
import variables from '@/assets/styles/helpers/_variables.scss'
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
import validacao from './validacao'

export default {
  name: "CadastroContato",
  directives: {mask},
  mixins: [validationMixin],
  components: {
    // searchUfCity: () => import('@/views/components/searchUfCity')
    FormAddCotacao: () => import('./formAddCotacao'),
    Modal: () => import('@/views/components/modal'),
    ConfirmarAlteracao: () => import('./formConfirmarAlteracao')
  },

  props: {
    configModaliade: {
      type: Array,
      default: () => []
    },

    activeModal: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    isActiveModal: false,
    loadingLimite: false,
    isActiveModalConfirmacao: false,
    headers: [
      { align: 'start', class: 'table-header', text: 'Modalidade', value: 'modalityId' },
      { align: 'end', class: 'table-header', text: 'Máximo V. Prêmio', value: 'value' },
      { align: 'end', class: 'table-header', text: '', value: 'action', sortable: false },
    ],
  }),

  watch: {
    'activeModal' (val) {
      if (val) {
        this.init()
      }
    }
  },

  mounted () {
    this.init()
  },

  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('cotacaoDinamica', ['dataConfigLimites']),
    ...validacao,
    variables: () => variables,

    valueErrors() {
      const errors = []
      if (!this.$v.dataConfigLimites.maxPrizeValue.$dirty) return errors
      !this.$v.dataConfigLimites.maxPrizeValue.required && errors.push('Campo obrigatório!')
      !this.$v.dataConfigLimites.maxPrizeValue.minValue && errors.push('Valor mínimo é R$ 50.000,00!')
      return errors
    }
    
  },

  methods: {
    ...mapActions('cotacaoDinamica', ['getItemsConfigLimites', 'editConfigLimites', 'setCotacao', 'deleteCotacao']),

    init() {
      this.getItemsConfigLimites()
    },

    submit () { 
      if (this.$v.$invalid) { 
        this.$v.$touch()
        return false 
      }

      this.$emit('registrarCotacao', this.palpite)
    },

    confirmarAlteracaoLimite () {
      this.isActiveModalConfirmacao = true
    },

    closeModalConfirmarAlteracao () {
      this.isActiveModalConfirmacao = false
    },

    confirmarAlteracao () {
      this.isActiveModalConfirmacao = false
      this.alterarLimite()
    },

    alterarLimite () {
      this.loadingLimite = true
      this.editConfigLimites(this.dataConfigLimites)
        .then(() => successSnackbar('Limite alterado com sucesso!'))
        .catch(e => errorSnackbar(e.error))
        .finally(() => this.loadingLimite = false)
    },

    closeModal () {
      this.$emit('closeModal', false)
    },

    clearForm () {
      this.$v.data.$reset()
      this.clearData()
    },

    openModalAddCotacao () {
      this.isActiveModal = true
    },

    closeModalAddCotacao () {
      this.isActiveModal = false
    },

    editItem (item, index) {
      this.setCotacao({ ...item, index })
      this.isActiveModal = true
    },

    deleteItem (item, index) {
      this.$swal({
        icon: 'warning',
        text: `Tem certeza que deseja excluir?`,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.value) {
          this.deleteCotacao(index)
        }
      })
    }
  },
  
  validations () {
    return  {
      dataConfigLimites: {
        maxPrizeValue: { required, minValue: minValue(50000) }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .form::v-deep {
   .modal-cadastro-form-col-imput {
      padding-top: 0;
      padding-bottom: 0;
    }
   
   .v-text-field.v-text-field--enclosed .v-text-field__details {
     padding: 0
   }
  }

  .list {
    padding-left: 0;
    &-item {
      display: inline-block;
      list-style: none;
      cursor: pointer;
      color: #5076ff;

      &.active {
        color: #9797ad;
      }
    }
  }
</style>